/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { PaperBox, Flex, Box } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import BackgroundImage from 'gatsby-background-image';
import Contacts from '../components/Contacts';

import sparkedEcho from '../assets/images/sparked-echo.png';
import creativeFolkestone from '../assets/images/creative-folkestone.png';
import prsFoundation from '../assets/images/prs-foundation.png';
import artsCouncil from '../assets/images/arts-council.png';
import pmStudio from '../assets/images/pm-studio.jpg';


const imgStyle = css`
  border-radius: 5px;
`;

const mainName = css`
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
`;

const linkStyle = css`
  font-weight: bold;
  text-decoration: underline;
`;

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const bottom = css`
  padding-bottom: 40px;
`;

const padding = css`
  padding-top: 50px;
  @media screen and (max-width: 600px) {
      padding-top: 100px;
    }
`;

const welcomeText = css`
  padding-bottom: 10px;
  padding-top: 0px;
`;

const WhoAreWe = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Who Are We - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
        margin="40px"
      >
        <Flex>
          <PaperBox 
            width={[1, '80%', '60%']}
            ml="auto"
            mr="auto"
            mt="50px"
            px={[3, 3, 5]}
            py={[3, 3, 3]}
            color={colors.secondary}
            textAlign="center"
          >
            <h1 className={padding}>BEACONS</h1>
`
            <h2>was brought to Folkestone by:</h2>
            <p><a href="https://alisonneighbourdesign.com/" className={linkStyle} target="_blank">Alison Neighbour</a> (concept, story, game design, scenography)</p>
            <p><a href="https://www.watershed.co.uk/studio/residents/tarim" className={linkStyle} target="_blank">Tarim</a> (game design, Sea Gooseberries software, website)</p>
            <p><a href="https://emilypeasgood.com/" className={linkStyle} target="_blank">Emily Peasgood</a> (composer)</p>
            <p><strong>Dave Kitschker</strong> (Sea Gooseberries hardware)</p>
            <p><a href="https://mangostone.org/" className={linkStyle} target="_blank">Mangostone</a> (Sea Gooseberries fabrication)</p>
            <p><strong>Ellie Foreman</strong> (website)</p>
            <p><strong>Al Harle</strong> (sound system design & build)</p>
            <p><a href="https://www.sarahjanebooth.co.uk/" className={linkStyle} target="_blank">Sarah Booth</a> (costume maker)</p>
            <p><strong>Simon Davenport</strong> (horns)</p>
            <p><strong>Cathy Blackwood</strong> (production assistant)</p>

            <h2>Oracles:</h2>
            <p><strong>Anna Braithwaite</strong></p>
            <p><strong>Francis Gush</strong></p>
            <p><strong>Samuel Loveless</strong></p>
            <p><strong>Emily Peasgood</strong></p>
            <p><strong>Sarah Rennix</strong></p>

            <h2>Sea Gooseberry Guardians:</h2>
            <p><strong>Ella Austin</strong></p>
            <p><strong>Di Burns</strong></p>
            <p><strong>Mikee Chen</strong></p>
            <p><strong>Katie Clements</strong></p>
            <p><strong>Carys Davies</strong></p>
            <p><strong>Olivia Harvey</strong></p>
            <p><strong>Katie Lloyd</strong></p>
            <p><strong>Natasha Luetchford</strong></p>
            <p><strong>Jenny Luddington</strong></p>
            <p><strong>Kay McLoughlin</strong></p>
            <p><strong>Cathy Rowsome</strong></p>
            <p><strong>James Walker-Osborn</strong></p>

            <p>Produced by <a href="https://www.sparkedecho.org/" className={linkStyle} target="_blank">Sparked Echo</a> and <a href="https://www.creativefolkestone.org.uk" className={linkStyle} target="_blank">Creative Folkestone</a></p>
            <p>With thanks to our supporters:</p>

            <Box bg={colors.white} pb={[0]}>
              <Flex align-items="center" justifyContent="center" wrap={['wrap', 'nowrap', 'nowrap']}>
                <Box textAlign="center" m={[3]}>
                  <a href="https://www.sparkedecho.org/" target="_blank">
                    <img
                      className={imgStyle}
                      alt="Sparked Echo logo"
                      src={sparkedEcho}
                      height="70"
                    />
                  </a>
                </Box>
                <Box textAlign="center" m={[3]}>
                  <a href="https://www.creativefolkestone.org.uk/" target="_blank">
                    <img
                      className={imgStyle}
                      alt="Creative Folkestone Logo"
                      src={creativeFolkestone}
                      height="40"
                    />
                  </a>
                </Box>
                <Box textAlign="center" m={[3]}>
                  <a href="https://prsfoundation.com/" target="_blank">
                    <img
                      className={imgStyle}
                      alt="PRS Foundation logo"
                      src={prsFoundation}
                      height="60"
                    />
                  </a>
                </Box>
                <Box textAlign="center" m={[3]}>
                  <a href="https://www.artscouncil.org.uk/" target="_blank">
                    <img
                      className={imgStyle}
                      alt="Arts Council Logo"
                      src={artsCouncil}
                      height="60"
                    />
                  </a>
                </Box>
                <Box textAlign="center" m={[3]}>
                  <a href="https://www.watershed.co.uk/studio/" target="_blank">
                    <img
                      className={imgStyle}
                      alt="PM Studio logo"
                      src={pmStudio}
                      height="60"
                    />
                  </a>
                </Box>
              </Flex>
            </Box>
            <p>And Ashley Family Foundation, Kent Wildlife Trust, White Cliffs Countryside Partnership, Kent Libraries; all our fantastic Quest hosts, and everyone who contributed wisdoms.</p>
            <p>Thank you for participating. We’d love your feedback. Contact us:</p>
            <Contacts></Contacts>
            <p className={bottom}>&nbsp;</p>
          </PaperBox>
        </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

export default WhoAreWe;
